import flagsmith from "flagsmith";
import { FlagsmithContextType, FlagsmithProvider } from "flagsmith/react";

/**
 * Provider component for managing feature flags using Flagsmith.
 * @param {object} props - The props for the FeatureFlagsProvider component.
 * @param {ReactNode} props.children - The child components.
 * @param {object} props.options - Additional options for the FlagsmithProvider.
 * @returns {JSX.Element} FeatureFlagsProvider component.
 */
const FeatureFlagsProvider = ({
    children,
    options,
}: Omit<FlagsmithContextType, "flagsmith" | "options"> & {
    options?: Partial<FlagsmithContextType["options"]>;
}) => {
    return (
        <FlagsmithProvider
            options={{
                environmentID: process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_ID || "",
                ...options,
            }}
            flagsmith={flagsmith}
        >
            {children}
        </FlagsmithProvider>
    );
};

export default FeatureFlagsProvider;
